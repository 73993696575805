import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import moment from 'moment'

import Layout from '../components/layout'
import PrivateRoute from '../components/privateRoute'
import PortableTextContent from '../components/portableTextContent'
import { Button } from 'semantic-ui-react'
import { DownloadLink, LinkWrapper } from '../components/ui/links'

export const NewsletterContentWrapper = styled.div`
  padding: 2rem 0;
`

const NewsletterTemplate = ({ data }) => {
  const { title, _rawContent, file, publishDate } = data.newsletter
  const { headerImage } = data

  return (
    <Layout
      headerText={title}
      headerSubtext={moment(publishDate, 'YYYY-MM-DD').format('LL')}
      headerImage={headerImage.childImageSharp}
    >
      {/* <h1>{title}</h1> */}
      <NewsletterContentWrapper>
        <PortableTextContent rawContent={_rawContent} />
      </NewsletterContentWrapper>
      <LinkWrapper>
        <DownloadLink href={file.asset.url} target="_blank" rel="noreferrer noopener">
          <Button positive>Download</Button>
        </DownloadLink>
      </LinkWrapper>
      <LinkWrapper>
        <Link to="/news/archive">View Newsletter Archive</Link>
      </LinkWrapper>
    </Layout>
  )
}

export default props => {
  return <PrivateRoute component={NewsletterTemplate} {...props} />
}

export const sponsorshipPageQuery = graphql`
  query NewsletterByID($id: String!) {
    newsletter: sanityNewsletter(id: { eq: $id }) {
      id
      _rawContent
      title
      publishDate
      file {
        asset {
          url
        }
      }
    }

    headerImage: file(relativePath: { eq: "candles.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
