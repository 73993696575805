import React from 'react';
import { Link } from 'gatsby';
import PortableText from '@sanity/block-content-to-react';
import { Button } from 'semantic-ui-react';

const serializers = {
  types: {
    code: ({ node }) => (
      <pre data-language={node.language}>
        <code>{node.code}</code>
      </pre>
    ),
  },
  marks: {
    internalLink: ({ mark, children }) => {
      return (
        <Link to={mark.href}>
          <Button positive>{children}</Button>
        </Link>
      );
    },
    externalLink: ({ mark, children }) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
  },
};

const PortableTextContent = ({ rawContent }) => (
  <PortableText serializers={serializers} blocks={rawContent} />
);

export default PortableTextContent;
