import styled from 'styled-components'

export const DownloadLink = styled.a`
  margin: 2rem 0;
  width: auto;
`

// Adds top and bottom margin to link
export const LinkWrapper = styled.div`
  margin: 2rem 0;
`
